body {
  font-size: 100%;
  font-family: 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.hide { display: none }
.clear { clear: both }

h1 {
  text-align: center;
  margin: auto;
  padding: 40vh 0 0;
  font-size: 5rem;
  text-transform: uppercase;
  color: white;
  text-shadow: 1px 2px 7px #000;
}
h2 {
  background-size: 100%;
  font-size: 3rem;
  padding: 10px 20px;
  text-transform: uppercase;
  color: white;
  margin: 10px 0;
}
h2 span {
  display: block;
  max-width: 900px;
  margin: auto;
}
h4 {
  text-align: center;
  margin: auto;
  padding: 0;
  font-size: 2rem;
  text-transform: uppercase;
}

.Nav {
  background: rgba(0,0,0,0.8);
  height: 75px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}
.Nav .logo img {
  display: block;
  width: 100px;
  margin: auto;
}
.Nav .social {
  position: fixed;
  top: 30px;
  right: 20px;
  float: right;
  margin: 0;
  padding: 0;
  list-style: none;
}
.Nav .social li {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}
.Nav .social li a {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
}

.Home .header {
  height: 100vh;
  background-size: cover;
}

.Home .content {
  padding: 20px;
  max-width: 900px;
  margin: auto;
}

.bearcat {
  display: block;
  margin: 10px auto;
  width: 100%;
}

.videoHolder {
  width: 80%;
  margin: auto;
  text-align: center;
  height: 100%;
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

textarea {
  width: 95%;
  height: 400px;
  margin: auto;
}

input:not([type="checkbox"]) {
  font-size: 1.2rem;
  padding: 10px;
  width: calc(95% - 20px);
  margin: 0 auto 10px;
}

.checkHold {
  clear: both;
  width: 175px;
  margin: auto;
}

.checkHold label {
  margin-left: 5px;
}

.action {
  display: block;
  padding: 10px 0;
  text-transform: uppercase;
  width: 300px;
  text-decoration: none;
  text-align: center;
  color: white;
  background: red;
  font-weight: bold;
  margin: 20px auto;
}